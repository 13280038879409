.main-content-extension {
    .menu-mobile-overlay {
        width: 100%;
        height: 100vh;
        position: absolute;
        background-color: white;
        top: 0;
        left: 0;
        margin: 0;
        z-index: 50;
        animation: slide-in-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        display: none;

        .menu-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            padding: 0.5rem;
            border: 2px solid black;
            background-color: #EAE8E8;
            display: flex;
            justify-content: center;
        }

        p {
            position: absolute;
            top: 1rem;
        }

        .mobile-menu-item {
            color: black;
            text-decoration: none;
            margin: 1rem 0;
            font-size: 2.5rem;
        }
        .menu-close {
            position: absolute;
            top: 1rem;
            right: 1rem;
            padding: 0.5rem;
            border: 2px solid black;
            background-color: #EAE8E8;
            display: flex;
            justify-content: center;
        }
    }

    .menu-desktop-overlay {
        background-color: white;
        border: solid black 2px;
        position: absolute;
        top: 2rem;
        right: 2rem;
        display: flex;
        flex-direction: column;
        width: 15rem;
        height: 15rem;
        align-items: center;
        justify-content: center;

        a {
            text-decoration: none;
            color: black;
            font-size: 1.4rem;
            margin-bottom: 0.5rem;

            &:hover {
                color: #707070;
            }
        }

        .desktop-menu-close {
            border: 2px solid black;
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 1rem;
            right: 1rem;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 80%;
                height: 80%;
            }
        }
    }

    .menu {
        position: absolute;
        top: 2rem;
        right: 2rem;
        display: flex !important;
        border: 2px solid black;
        padding: 1rem;
        background-color: white;
    
        img {
            margin-bottom: -0.2rem;
        }
    
        &:hover {
            cursor: pointer;
        }
    }
}

@keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0);
    }
}

@media(max-width: 950px) {
    .main-content-extension {
        .menu-desktop-overlay {
            display: none;
        }

        .menu-mobile-overlay {
            display: flex;
        }
    }

}
