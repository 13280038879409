#about-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .about-hero-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .about-image-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 12rem;
            height: 12rem;
            position: relative;

            h2 {
                position: absolute;
                font-size: 3rem;
                top: 0.5rem;
                right: -4rem;
            }
    
            img {
                width: 100%;
                height: 100%;
            }
        }
        
        .border-line {
            background-color: white;
            border: 2px black solid;
            width: 100%;
            height: 0.5rem;
            box-shadow: 0px 6px 8px 2px rgba(0,0,0,0.6);
        }

    }

    .about-description {
        p {
            font-size: 1.3rem;
        }
    }

    .resume {
        margin-top: 4rem;
        position: relative;

        .resume-button {
            text-decoration: none;
            color: black;
            padding: 0rem 2.5rem;
            background-color: white;
            border: 1px solid black;
            display: flex;
            flex-direction: column;
            z-index: 2;
            
            &:hover {
                outline: 1px black solid;
                cursor: pointer;
            }
        }

        .resume-pic {
            align-self: center;
            position: absolute;
            width: 8rem;
            height: 8rem;
            top: -5.5rem;
            z-index: -2;
        }
    }
}

@media(max-width: 950px) {
    #root{
        #about-container {
            .about-hero-container {
                margin-top: 5rem;
                width: 70%;
            }
            .about-image-container {
                width: 8rem;
                height: 8rem;

                h2 {
                    font-size: 2rem;
                    right: -3rem;
                }
            }

            .about-description {
                width: 80%;
                p {
                    font-size: 1.1rem;
                }
            }

            .resume {
                margin-bottom: 7rem;
            }
        } 
    }
}