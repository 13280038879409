// GLOBAL
@import url('https://fonts.googleapis.com/css2?family=Do+Hyeon&display=swap');

body {
    background-color: #EAE8E8;
    margin: 0;
    font-family: 'Do Hyeon', sans-serif;
    font-size: 1.4rem;
    font-weight: 300;
    
    a,p {
        font-size: 1.1rem;
    }
}

#root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 100%;
    min-height: 100vh;

    footer {
        position: absolute;
        bottom: 1rem;
        width: 40rem;
        display: flex;
        justify-content: center;
    }

    main {
        height: 100%;
        width: 50rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & > * {
            margin-bottom: 1rem;
        }

        .menu-overlay {
            width: 100%;
            height: 100%;
            position: absolute;
            background-color: white;
            margin: 0;
            z-index: 2;
            animation: slide-in-top 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            p {
                position: absolute;
                top: 1rem;
            }

            .mobile-menu-item {
                color: black;
                text-decoration: none;
                margin: 1rem 0;
                font-size: 2.5rem;
            }
            .menu-close {
                position: absolute;
                top: 1rem;
                right: 1rem;
                padding: 0.5rem;
                border: 2px solid black;
                background-color: #EAE8E8;
                display: flex;
                justify-content: center;
            }
        }
        .menu-index {
            position: absolute;
            display: none;
            top: 2rem;
            right: 2rem;
            border: 2px solid black;
            padding: 1rem;
            background-color: white;

            img {
                margin-bottom: -0.2rem;
            }
        }

        .portrait-container {
            border: 2px black solid;
            background-color: white;
            width: 16rem;
            height: 16rem;

            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .intro-wysiwyg {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1,h3 {
                margin: 0;
            }
        }

        .menus-container {
            height: 100%;
            width: 60%;
            display: flex;
            justify-content: space-evenly;

            .menu-item {
                text-decoration: none;
                padding: 1rem;
                border: 1px solid black;
                background-color: white;
                width: 4rem;
                display: flex;
                justify-content: center;
                color: black;

                &:hover {
                    cursor: pointer;
                    outline: 1px black solid;
                }
            }
        }

        .social-icons {
            width: 30%;
            display: flex;
            justify-content: space-evenly;

            & > * {
                width: 1.75rem;
                height: 1.75rem;

                &:hover {
                    cursor: pointer;
                    animation: jello 0.5s both;

                }
                img {
                    object-fit: contain;
                    height: 100%;
                    width: 100%;
                }
            }

        }
    }
}

@keyframes slide-in-top {
    0% {
      transform: translateY(-1000px);
    }
    100% {
      transform: translateY(0);
    }
  }

@keyframes jello {
    0% {
      transform: scale3d(1, 1, 1);
    }
    30% {
      transform: scale3d(1.25, 0.75, 1);
    }
    40% {
      transform: scale3d(0.75, 1.25, 1);
    }
    50% {
      transform: scale3d(1.15, 0.85, 1);
    }
    65% {
      transform: scale3d(0.95, 1.05, 1);
    }
    75% {
      transform: scale3d(1.05, 0.95, 1);
    }
    100% {
      transform: scale3d(1, 1, 1);
    }
  }

  @media(max-width: 645px) {
    #root {
        width: 100%;
        margin: auto;
    
        main {
            width: 100%;
            height: 100%;

            .menu-index {
                display: block;
            }

            .menus-container {
                display: none;
            }

            .intro-wysiwyg {
                width: calc(100% - 1.5rem);
                justify-content: center;
                align-items: center;
                margin-left: 1.5rem !important;

                & > * {
                    margin: auto;
                    width: 100%;
                    text-align: center;
                }
            }
        }
        footer {
            width: 100%;
        }
    }
  }